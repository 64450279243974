*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.body-content {
    background-color: rgb(255, 255, 255);
    height: calc(100vh - 52px);
    width: 100%;
    overflow-y: auto;
}

.wbFloatLeft {
    height: 100%;
}


.wbMainPageHeight {
    height: calc(100vh - 52px);
    display: flex;
}

.wbHideScroll {
    overflow: hidden !important;
  }
  .wbScroll, .MuiDataGrid-virtualScroller {
    overflow: overlay !important;
  }
  .wbScrollY {
    overflow-y: scroll;
  }
  .wbScrollX {
    overflow-x: scroll;
  }
  .wbScroll::-webkit-scrollbar, .MuiDataGrid-virtualScroller::-webkit-scrollbar {
    display: none;
    width: 0.25rem;
    height: 0.25rem;
  }
  .wbScroll:hover::-webkit-scrollbar, .MuiDataGrid-virtualScroller:hover::-webkit-scrollbar {
    display: inline;
  }
  .wbScroll::-webkit-scrollbar-thumb, .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
    background-color: #dadada;
    border-radius: 1.5rem;
  }
  .wbScroll::-webkit-scrollbar:hover, .MuiDataGrid-virtualScroller::-webkit-scrollbar:hover {
    width: 0.5rem;
    height: 0.5rem;
  }
  .wbScrollHover::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

