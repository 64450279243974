.login_page .logo {
    margin: 0.5vw;
    width: auto;
}
/* .login_page .coverImage {
    background-size: cover;
    background-position: center;
} */
.login_page .welcome {
    font-size: 1.5rem;
    font-weight: 700;
    color: #002947;
}
.login_page .loginText {
    font-size: 1rem;
    font-weight: 500;
    color: #002947;
}
@media only screen and (max-width: 599px) {
    .login_page .welcome {
        font-size: 2.5rem;
        font-weight: 700;
        color: #1976D2;
    }
    .login_page .loginText {
        font-size: 1.9rem;
    }
    .login_page .loginBtn {
        font-size: 2rem;
    }

}